<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2024-02-07 09:31:02
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-20 14:03:20
-->
<!-- 富文本编辑器组件 -->
<template>
  <div class="rich-text-editor">
    <Toolbar
    id="editor"
      class="tool-bar"
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      class="editor-inner"
      style="height: 440px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onChange="_onChange"
      @onCreated="_onCreated"
    />
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { getCookie } from '@/utils/util'
export default {
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        toolbarKeys: [
          'headerSelect', // 正文
          'blockquote', // 引号
          '|', // 分隔线
          'bold', // 加粗
          'underline', // 下划线
          'italic', // 倾斜

          // 菜单组，包含多个菜单
          {
            key: 'group-more-style',
            title: '更多',
            iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>',
            menuKeys: [
              'through',
              'code',
              'sup',
              'sub',
              'clearStyle'
            ]
          },
          'color',
          'bgColor',
          '|',
          'fontSize',
          'fontFamily',
          'lineHeight',
          '|',
          'bulletedList',
          'numberedList',
          'todo',
          {
            key: 'group-justify',
            title: '对齐',
            iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              'justifyLeft',
              'justifyRight',
              'justifyCenter',
              'justifyJustify'
            ]
          },
          '|',
          'emotion',
          'insertLink',
          {
            key: 'group-image',
            title: '图片',
            iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: [
              'uploadImage'
            ]
          },
          'insertTable',
          'codeBlock',
          'divider',
          '|',
          'undo',
          'redo'

        ]

      },
      editorConfig: {
        placeholder: '请输入内容...',

        MENU_CONF: {
          //   配置上传图片
          uploadImage: {
            server: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload',
            fieldName: 'files',
            meta: {
              token: getCookie('token')
            },
            // 请求头
            headers: { token: getCookie('token') },
            timeout: 5 * 1000, // 5s 超时时间
            // 选择文件时的类型限制，默认为['image/*'] 如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            maxFileSize: 30 * 1024 * 1024, // 1g //设置大点 不然图片过大会报错
            // base64LimitSize: 1000000 * 1024, // 1g 以下插入 base64
            // 自定义插入图片
            customInsert (res, insertFn) {
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(res.data[0].url, '', res.data[0].url)
            },
            // 上传之前触发
            onBeforeUpload (file) {
              // console.log(file, 'hhhh')
              // onBeforeUpload(file) {    // JS 语法
              // file 选中的文件，格式如 { key }
              // return file
            },
            // 上传进度的回调函数
            onProgress (progress, number) {
              // progress 是 0-100 的数字
              // console.log('progress', progress)
            },
            // 单个文件上传成功之后
            onSuccess (file, res) {
              // console.log(`${file.name} 上传成功`, res)
            },
            // 单个文件上传失败
            onFailed (file, res) {
              // console.log('上传失败', res)
            },
            // 上传错误，或者触发 timeout 超时
            onError (file, err, res) {
              // console.log(`${file.name} 上传出错`, err, res)
            }
          }
        }
      },
      mode: 'simple' // 'default' or 'simple'
    }
  },
  created () {
    // console.log(this.content, 'this.content---------')
    this.html = this.content
  },
  mounted () {
  },
  methods: {
    _onChange (editor) {
      // console.log(Toolbar.getToolbar(editor), 'this.toolbarConfig888')
      this.$emit('changeData', this.html)
    },
    _onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错

      // console.log(this.editor, this.toolbarConfig, 'this.toolbarConfig')
      // this.toolbarConfig = {
      //   // toolbarKeys: [// 自定义菜单选项
      //   // //   // 菜单 key
      //   // //   'headerSelect',
      //   // //   // 分割线
      //   // //   '|',

      //   // //   // 菜单 key
      //   // //   'bold',
      //   // //   'italic',
      //   // //   // 菜单组，包含多个菜单
      //   // //   {
      //   // //     key: 'group-more-style', // 必填，要以 group 开头
      //   // //     title: '更多样式', // 必填
      //   // //     iconSvg: '<svg>123</svg>', // 可选
      //   // //     menuKeys: ['through', 'code', 'clearStyle'] // 下级菜单 key ，必填
      //   // //   },
      //   // //   'underline',
      //   // //   'color',
      //   // //   'bgColor'
      //   // // ],
      //   // // excludeKeys: [
      //   // //   'fullScreen',
      //   // //   'bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color', 'bgColor', 'fontSize', 'fontFamily',
      //   // //   'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight', 'insertImage', 'deleteImage',
      //   // //   'editImage', 'viewImageLink', 'imageWidth30', 'imageWidth50', 'imageWidth100', 'divider', 'emotion', 'insertLink', 'editLink',
      //   // //   'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1', 'header2', 'header3', 'header4', 'header5', 'todo',
      //   // //   'redo', 'undo', 'fullScreen', 'enter', 'bulletedList', 'numberedList', 'insertTable', 'deleteTable', 'insertTableRow',
      //   // //   'deleteTableRow', 'insertTableCol', 'deleteTableCol', 'tableHeader', 'tableFullWidth', 'insertVideo', 'uploadVideo', 'editVideoSize',
      //   // //   'uploadImage', 'codeSelectLang', 'group-more-style', 'sub', 'sup'
      //   // ]
      // }
    }

  },

  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
/deep/.tool-bar{
  div{
    // div:nth-last-child(1){//隐藏全屏
    //   display: none!important;
    // }
  }
}

</style>
